import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import HomeLayout from "../HomeLayout/HomeLayout"
import CoolLightbox from "../LightBox/CoolLightBox"
const Page1 = ({onChange}) => {
  const [isOpen, setOpen] = useState(false)
  const [currentImageIndex, setCurrentIndex] = useState(0)

  const data = useStaticQuery(graphql`
    query {
      Page1: allFile(
        sort: { order: ASC, fields: name }
        filter: { sourceInstanceName: { eq: "CorporateIdentityPage1" } }
      ) {
        nodes {
          id
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      CorporateIdentityInfoPage1: allFile(
        sort: { order: ASC, fields: name }
        filter: { sourceInstanceName: { eq: "CorporateIdentityInfoPage1" } }
      ) {
        nodes {
          id
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  var images = []
  data.CorporateIdentityInfoPage1.nodes.map(img => {
    var src = img.childImageSharp.fluid.src
    images.push({
      src
    })
  })
 return (
    <HomeLayout>
      <>
        {data.Page1.nodes.map((img, index) => {
          return (
            <div
              onClick={() => {
                setCurrentIndex(index)
                setOpen(true)
                onChange(false)
              }}
            >
              <Img fluid={img.childImageSharp.fluid} />
            </div>
          )
        })}
        <div style={{backgroundColor:"red"}}>
          <CoolLightbox
            images={images}
            setCurrentIndex={setCurrentIndex}
            currentImageIndex={currentImageIndex}
            isOpen={isOpen}
            onClose={() =>{ setOpen(false)
              onChange(true)}}
          />
          </div>
      </>
    </HomeLayout>
  )
}
export default Page1
