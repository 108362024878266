import React, { useState } from "react"
import { graphql, useStaticQuery,Link } from "gatsby"
import Img from "gatsby-image"
import HomeLayout from "../HomeLayout/HomeLayout"
import CoolLightbox from "../LightBox/CoolLightBox"
import { useMediaQuery } from "react-responsive"
const Page3 = ({onChange}) => {
  const [isOpen, setOpen] = useState(false)
  const [currentImageIndex, setCurrentIndex] = useState(0)
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 720px)",
  })
  const data = useStaticQuery(graphql`
    query {
      Page3: allFile(
        sort: { order: ASC, fields: name }
        filter: { sourceInstanceName: { eq: "CorporateIdentityPage3" } }
      ) {
        nodes {
          id
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      CorporateIdentityInfoPage3: allFile(
        sort: { order: ASC, fields: name }
        filter: { sourceInstanceName: { eq: "CorporateIdentityInfoPage3" } }
      ) {
        nodes {
          id
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  var images = []
  data.CorporateIdentityInfoPage3.nodes.map(img => {
    var src = img.childImageSharp.fluid.src
    images.push({
      src,
    })
  })

  return (
    <HomeLayout>
        <>
          {data.Page3.nodes.map((img, index) => {
            if(index ===3) return  <Link to="/Services/Architecture">
            <Img fluid={img.childImageSharp.fluid} />
            </Link>
            return (
              <div
                onClick={() => {
                  setCurrentIndex(index)
                  setOpen(true)
                  onChange(false)
                }}
              >
                <Img fluid={img.childImageSharp.fluid} />
              </div>
            )
          })}
         <div style={{opacity:0}}>
         <Img fluid={data.Page3.nodes[0].childImageSharp.fluid} />
         </div>
         <div style={{opacity:0}}>
         <Img fluid={data.Page3.nodes[0].childImageSharp.fluid} />
         </div>
         <div style={{opacity:0}}>
         <Img fluid={data.Page3.nodes[0].childImageSharp.fluid} />
         </div>
         <div style={{opacity:0}}>
         <Img fluid={data.Page3.nodes[0].childImageSharp.fluid} />
         </div>

         <CoolLightbox  images={images}
            setCurrentIndex={setCurrentIndex}
            currentImageIndex={currentImageIndex}
            isOpen={isOpen}
            onClose={() => {setOpen(false)
              onChange(true)}}
            />
        </>
        </HomeLayout>
  )
}
export default Page3

